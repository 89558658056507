@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

*::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	border-radius: 8px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar
{
	width: 8px;
    border-radius: 8px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar-thumb
{
	border-radius: 8px;
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
	background-color: #555;
}